export default {
    'notice.充值成功': '充值到账',
    'notice.领取奖励成功': '领取成功',
    'notice.您未参与才活动': '未参与此活动',
    'notice.已经领取过了': '已经领取过了',
    'notice.提款申请成功，请等待': '提款申请成功，请等待',
    'notice.密码错误': '密码错误',
    'notice.验证码已发送': '验证码已发送',
    'notice.提款失败': '提款失败',
    'notice.验证码有误或已过期': '验证码有误或已过期',
    'notice.该手机号已使用，请更换': '该手机号已使用，请更换',
    'notice.您当前账号已绑定过手机号': '您当前账号已绑定过手机号',
    'notice.渠道维护请重新选择': '渠道维护请重新选择',
    'notice.此充值方式不能参与活动': '此充值方式不能参与活动',
    'notice.小于此渠道最低充值金额': '小于此渠道最低充值金额',
    'notice.中间件配置不存在禁止登录': '中间件配置不存在禁止登录',
    'notice.正在维护': '渠道{channel}正在维护',
    'notice.领取新版注册送金币': '已成功领取{amount}枚金币',
    'notice.领取新版注册送金币最后一次领取': '已成功领取{amount}枚金币，你领完了所有奖励，活动将关闭',
    'notice.appKey不一致禁止登录': 'appKey不一致禁止登录',
    'notice.账号在其它地方被登录': '账号在其它地方被登录',
    'notice.非常抱歉，您的提款申请失败，可能是卡号错误或通道维护等原因。请找客服核对': '非常抱歉,您的提款申请失败,可能是卡号错误或通道维护等原因,请找客服核对',
    'notice.提款金额不能大于': '提款金额不能大于{amount}',
    'notice.游戏暂未开放': '游戏暂未开放',
    'notice.账号被其它设备登录': '系统检测到您的账户可能在多个设备上登录！如果您不知道这一点，请立即更改您的账户密码！',
    'notice.该银行卡号绑定在其他账号中': '该银行卡号绑定在其他账号中'
};