export default {
  'daily_checkin.popup.标题': '每日签到',
  'daily_checkin.popup.描述': '抽多少得多少',
  'daily_checkin.popup.按钮': '开奖',

  'daily_checkin.标题': '每日签到',
  'daily_checkin.今日奖励': '今日奖励',
  'daily_checkin.领取': '领取',
  'daily_checkin.免费': '免费',
  'daily_checkin.免费描述': '当日流水达到{amount}可领取次日免费签到金币',
  'daily_checkin.第一天': 'DAY1',
  'daily_checkin.第二天': 'DAY2',
  'daily_checkin.第三天': 'DAY3',
  'daily_checkin.第四天': 'DAY4',
  'daily_checkin.第五天': 'DAY5',
  'daily_checkin.第六天': 'DAY6',
  'daily_checkin.第七天': 'DAY7',
  'daily_checkin.充值': '充值',
  'daily_checkin.动态描述': '提现无流水要求',
  'daily_checkin.选择充值方式': '选择充值方式',
  'daily_checkin.下订单失败': '下订单失败',
  'daily_checkin.下订单失败请尝试大额支付或其他支付通道': '下订单失败，请尝试大额支付或其他支付通道',
  'daily_checkin.正在维护': '正在维护',
  'daily_checkin.领取成功': '领取成功'

}