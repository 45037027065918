export default {
  'home.minbanner.activity1.标题': 'Newcomer Special',
  'home.minbanner.activity1.描述': 'Up to',
  'home.minbanner.activity1.全部': 'Gifted',
  'home.minbanner.activity3.标题': 'Daily Sign-In',
  'home.minbanner.activity3.描述': 'Get what you draw',
  'home.minbanner.activity4.标题': 'Become a VIP and Win Big',

  'home.game.lobby': 'All',
  'home.game.hot': 'Hot',
  'home.game.slots': 'Slots',
  'home.game.BY': 'Fishing',
  'home.game.casino': 'Poker Games',
  'home.game.SX': 'Live Casino',
  'home.game.TY': 'Sports',
  'home.game.NEW': 'New',
  'home.game.AG': 'AG',
  'home.game.BBIN': 'BBIN',
  'home.game.CMD': 'CMD',
  'home.game.CQ9': 'CQ9',
  'home.game.EVO': 'EVO',
  'home.game.FBGAME': 'FBGAME',
  'home.game.JDB': 'JDB',
  'home.game.JILI': 'JILI',
  'home.game.LHDJ': 'LHDJ',
  'home.game.NEXTSPIN': 'NEXTSPIN',
  'home.game.PG': 'PG',
  'home.game.PLAYNGO': 'PLAYNGO',
  'home.game.PLAYTECH': 'PLAYTECH',
  'home.game.PP': 'PP',
  'home.game.REDTIGER': 'REDTIGER',
  'home.game.RELAXGAMING': 'RELAX-GAMING',
  'home.game.SA': 'SA',
  'home.game.SABA': 'SABA',
  'home.game.SPRIBECASINO': 'SPRIBE-CASINO',
  'home.game.SUPERSPADE': 'SUPERSPADE',
  'home.game.V8': 'V8',
  'home.game.WM': 'WM',
  'home.game.WX168': 'WX168',
  'home.game.查看全部': 'View All',

  'home.exitdownpopup.提示': 'Tips', 
  'home.exitdownpopup.内容': 'Dear player, due to network issues, your {coin} coins on the {platform} platform have not been settled yet. Please wait patiently for a while.',
  'home.exitdownpopup.联系客服': 'Contact Support',

  'home.share.加入我们的社区': 'Join Our Community',

  'home.icons.支持的充值平台': 'Supported Payment Platforms',
  'home.icons.赞助和博彩责任': 'Partners',

  'home.metadesc.赞助和博彩责任': '7WIN.GAME',
  'home.metadesc.描述': 'With a player-centric approach, 7win.game is able to satisfy millions of gamblers across the globe. 7win.game has its priority set on its community, ensuring an everlasting and endlessly entertaining gambling experience. Your use of and access to 7win.game signifies that you fully understand and agree to be legally bound by the contents of our Terms of Service and Responsible Gaming Policy.',

  'home.selectsession.门槛': 'Conditions', 
  'home.selectsession.初级场': 'Primary', 
  'home.selectsession.中级场': 'Intermediate',
  'home.selectsession.高级场': 'Advanced',
  'home.selectsession.精英场': 'Elite',
  'home.selectsession.余额不足': 'Insufficient Balance',
  'home.selectsession.只有充值金才能玩': 'The current game only supports betting with recharged coins',
  'home.selectsession.充值金币余额不满足当前场次进入门槛': 'The recharged coins balance does not meet the entry requirements for the current session',
  'home.selectsession.当前场次条件是xx': 'The session conditions are {min}-{max}, you do not meet the requirements!',
  'home.selectsession.当前余额不足': 'Insufficient balance, please recharge',
  'home.entergame.用户余额不足': 'Insufficient user balance',
  'home.entergame.上分失败': 'Failed to add score',
  'home.entergame.三方游戏进入提示': 'The current game only supports betting with recharged assets! The exchange rate for the current Platform ({platform_name}) is {rate}:1! Your current deposit balance is {balance}! After entering the game, your available assets will be {coin}!',
  'home.entergame.三方游戏不能进入提示': 'The current game only supports betting with recharged assets! Your recharged coins are insufficient! You cannot enter the current game!',

}