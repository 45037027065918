export default {
  'acting.代理': '代理',

  'acting.详情': '详情',
  'acting.奖励': '奖励',
  'acting.佣金': '佣金',
  'acting.规则': '规则',

  'acting.generalboard.邀请朋友来获取': '邀请好友可获取',
  'acting.generalboard.人': '人',
  'acting.generalboard.邀请奖励': '邀请奖励',
  'acting.generalboard.高达': '至高',
  'acting.generalboard.佣金奖励': '佣金奖励',
  'acting.generalboard.描述': 'Get {amount} for each friend you invite, plus a up to 90% commission on their wagers. Enjoy consistent commissions, whether they win or lose. Start earning now!',
  'acting.generalboard.邀请链接': '邀请链接',
  'acting.generalboard.分享到社交平台': '分享到社交平台',
  'acting.generalboard.拉新表现': '拉新表现',
  'acting.generalboard.今日拉新': '今日拉新',
  'acting.generalboard.本周拉新': '本周拉新',
  'acting.generalboard.直属拉新表现': '直属拉新表现',
  'acting.generalboard.非直属拉新表现': '非直属拉新表现',
  'acting.generalboard.下级详情': '下级详情',
  'acting.generalboard.下级总人数': '下级总人数',
  'acting.generalboard.直属下级人数': '直属下级人数',
  'acting.generalboard.非直属下级人数': '非直属下级人数',
  'acting.generalboard.总奖励': '总奖励',
  'acting.generalboard.总佣金': '总佣金',
  'acting.generalboard.分享描述': '成为代理,月入百万!邀请奖励{amount},佣金高达90%!',
  'acting.generalboard.tip.复制成功': '复制成功',

  'acting.reward.邀请里程碑': '邀请里程碑',
  'acting.reward.邀请好友数量达到要求后可领取对应奖励': '邀请好友数量达到要求后可领取对应奖励',
  'acting.reward.邀请': '邀请',
  'acting.reward.人': '人',
  'acting.reward.领取': '领取',
  'acting.reward.直属下级人数': '直属下级人数',
  'acting.reward.邀请奖励': '邀请奖励',
  'acting.reward.直属下级绑定成功可获得': '直属下级绑定成功可获得',
  'acting.reward.今日邀请': '今日邀请',
  'acting.reward.总邀请': '总邀请',
  'acting.reward.今日奖励': '今日奖励',
  'acting.reward.邀请总奖励': '邀请总奖励',
  'acting.reward.今日邀请人数': '今日邀请人数',
  'acting.reward.邀请总人数': '邀请总人数',
  'acting.reward.用户ID': '用户ID',
  'acting.reward.昵称': '昵称',
  'acting.reward.奖励': '奖励',
  'acting.reward.展示前50条信息': '展示前50条信息',
  'acting.reward.暂无记录': '暂无记录',
  'acting.reward.领取成功': '领取成功',

  'acting.commission.本周佣金信息': '本周佣金信息',
  'acting.commission.下级总充值': '下级总充值',
  'acting.commission.直属下级佣金': '直属下级佣金',
  'acting.commission.非直属下级佣金': '非直属下级佣金',
  'acting.commission.未领取佣金': '未领取佣金',
  'acting.commission.领取佣金': '领取佣金',
  'acting.commission.领取记录': '领取记录',
  'acting.commission.佣金总和': '佣金总和',
  'acting.commission.领取时间': '领取时间',
  'acting.commission.直属佣金': '直属佣金',
  'acting.commission.非直属佣金': '非直属佣金',
  'acting.commission.总和': '总和',
  'acting.commission.展示前50条信息': '展示前50条信息',
  'acting.commission.暂无记录': '暂无记录',
  'acting.commission.领取成功': '领取成功',
}