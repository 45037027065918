export default {
  "mine.退出登录": "退出登录",
  "mine.用户ID": "用户ID",
  "mine.XP到下一个等级": "XP 到下一个等级",
  "mine.vip": "VIP",
  "mine.充值": "充值",
  "mine.下注": "下注",

  "mine.menus.充值": "充值",
  "mine.menus.提现": "提现",
  "mine.menus.账户明细": "账户明细",
  "mine.menus.代理": "代理",
  "mine.menus.每日签到": "每日签到",
  "mine.menus.vip": "VIP",
  "mine.menus.下注记录": "下注记录",
  "mine.menus.邮件通知":"邮件通知",
  "mine.menus.注册送": "注册送",
  "mine.menus.tip.活动未开启": "活动未开启",

  "mine.userdetail.用户ID": "用户ID",
  "mine.userdetail.总输赢": "总输赢",
  "mine.userdetail.总下注": "总下注",
  "mine.userdetail.仅计算当月数据": "仅计算当月数据",
  "mine.userdetail.最喜欢玩的3款游戏": "最喜欢玩的3款游戏",
  "mine.userdetail.用户信息": "用户信息",
  "mine.userdetail.绑定手机": "绑定手机",
  "mine.userdetail.绑定邮箱": "绑定邮箱",
  "mine.userdetail.更换": "更换",
  "mine.userdetail.绑定": "绑定",

  "mine.replacename.用户名修改": "用户名修改",
  "mine.replacename.用户名": "用户名",
  "mine.replacename.请输入用户名": "请输入用户名",
  "mine.replacename.确认": "确认",
  "mine.replacename.tip.请输入用户名": "请输入用户名",

  "mine.replaceemail.绑定邮箱": "绑定邮箱",
  "mine.replaceemail.邮箱": "邮箱",
  "mine.replaceemail.请输入邮箱": "请输入邮箱",
  "mine.replaceemail.确认": "确认",
  "mine.replaceemail.tip.请输入邮箱": "请输入邮箱",
  "mine.replaceemail.tip.请输入正确的邮箱地址": "请输入正确的邮箱地址",

  "mine.betrecord.下注记录": "下注记录",
  "mine.betrecord.最多展示前100条下注详情": "最多展示前100条下注详情",
  "mine.betrecord.暂无下注记录": "暂无下注记录",
  "mine.betrecord.下注号": "下注号",
  "mine.betrecord.时间": "时间",
  "mine.betrecord.下注金额": "下注金额",
  "mine.betrecord.输赢": "输赢",
  
  "mine.email.邮件通知": "邮件通知",
  "mine.email.已领取": "已领取",
  "mine.email.未领取": "未领取",
  "mine.email.收起": "收起",
  "mine.email.展开": "展开",
  "mine.email.暂无邮件": "暂无邮件",
  "mine.email.type.VIP返利提取成功": "VIP返利",
  "mine.email.value.VIP返利提取成功": "已成功获得返利金额{amount}",
  "mine.email.type.幸运签到": "每日签到",
  "mine.email.value.幸运签到": "奖励的金币{amount}已到账",
  "mine.email.type.NEWCOMER SPECIAL": "注册送",
  "mine.email.value.NEWCOMER SPECIAL": "感谢您参与新的特别优惠!礼品金额{amount}已锁定!用充值的黄金下注解锁!解锁金币后提款不需要交易额!有关更多规则，请查看活动详情!",
  "mine.email.type.提款成功": "提款成功",
  "mine.email.value.提款成功": "恭喜,您已成功提款{amount}。如果提款状态成功,但您尚未实际收到款项,银行通常会在24小时内转账,请耐心等待!如果您在24小时后仍未收到款项,请进入游戏并联系客服!",
  "mine.email.type.提款失败": "提款失败",
  "mine.email.value.提款失败": "非常抱歉,您的提款申请失败,可能是卡号错误或通道维护等原因,请找客服核对",
  "mine.email.type.提现扣除活动赠送的金币": "提现扣除活动赠送的金币",
  "mine.email.value.提现扣除活动赠送的金币": "提现扣除活动赠送的金币:{amount}",
  "mine.email.tip.领取成功": "领取成功",

  "mine.accountdetail.账户明细": "账户明细",
  "mine.accountdetail.可提现": "可提现",
  "mine.accountdetail.金额": "金额",
  "mine.accountdetail.充值金币": "充值金币",
  "mine.accountdetail.签到金币": "签到金币",
  "mine.accountdetail.充值金详情": "充值金详情",
  "mine.accountdetail.签到金详情": "签到金详情",
  "mine.accountdetail.提现": "提现",
  "mine.accountdetail.流水": "流水",
  "mine.accountdetail.余额": "余额",
  "mine.accountdetail.无流水要求": "无流水要求",
  "mine.accountdetail.流水倍数": "流水倍数 * {rate}",
  "mine.accountdetail.加载中": "加载中",
  "mine.accountdetail.desc.13014": "这枚硬币不能取款,完成后在0.1轮投注中,您可以参加活动和退款!",
  
  "mine.vip.VIP详情": "VIP详情",
  "mine.vip.成为VIP您可获得以下奖励": "成为VIP您可获得以下奖励",
  "mine.vip.VIP奖金": "VIP奖金",
  "mine.vip.提现福利": "提现福利",
  "mine.vip.下注返利": "下注返利",
  "mine.vip.XP到下一个等级": "XP 到下一个等级",
  "mine.vip.充值": "充值",
  "mine.vip.提现": "提现",
  "mine.vip.下注": "下注",
  "mine.vip.成为VIP可获取更多福利": "成为VIP可获取更多福利!",
  "mine.vip.完成以下任意任务即可成为VIP": "完成以下任意任务即可成为VIP",
  "mine.vip.充值xx以上": "充值{amount}以上",
  "mine.vip.完成一次充值+一次提现": "完成一次充值 + 一次提现",
  "mine.vip.代理收入达到xx以上": "代理收入达到{amount}以上",
  "mine.vip.维持当前VIP要求":"维持当前VIP要求",
  "mine.vip.下注金额超过xx":" 下注金额超过{symbol}{amount}",
  "mine.vip.当前VIP享受以下福利": "当前VIP享受以下福利",
  "mine.vip.升级奖金": "升级奖金",
  "mine.vip.可获得xx": "可获得{symbol}{amount}",
  "mine.vip.领取": "领取",
  "mine.vip.已领取": "已领取",
  "mine.vip.本周充值返利": "本周充值返利",
  "mine.vip.最小充值": "最小充值",
  "mine.vip.去充值": "去充值",
  "mine.vip.提现返利": "提现返利",
  "mine.vip.每日最高可提现": "每日最高可提现",
  "mine.vip.游戏下注返利": "游戏下注返利",
  "mine.vip.slot": "Slot",
  "mine.vip.棋牌": "棋牌",
  "mine.vip.视讯": "视讯",
  "mine.vip.体育": "体育",

}