export default {
  'activity.活动': '活动',
  'activity.立即参与': '立即参与',
  'activity.活动时间': '活动时间',
  'activity.活动未开启': '活动未开启',



  'activity.activity1.标题': '新人特惠',
  'activity.activity1.描述': '至高',
  'activity.activity1.全部': '赠送',
  'activity.activity1.活动描述': '充值最多赠送{rate}%',



  'activity.activity2.活动描述': '成为代理!月入百万!',



  'activity.activity3.标题': '每日签到',
  'activity.activity3.描述': '抽多少送多少',
  'activity.activity3.活动描述': '每日签到好礼不断',



  'activity.activity4.标题': '成为VIP领大奖',
  'activity.activity4.活动描述': 'VIP领取百万豪礼',



  'activity.coinname.10001': '10001',
  'activity.coinname.11001': '11001',
  'activity.coinname.12001': '绑定银行卡金币',
  'activity.coinname.12002': '12002',
  'activity.coinname.12003': '12003',
  'activity.coinname.12004': 'VIP',
  'activity.coinname.12005': '注册金币',
  'activity.coinname.12006': 'TG金币',
  'activity.coinname.12007': '闯关金币',
  'activity.coinname.13001': '代理拉新',
  'activity.coinname.13005': '13005',
  'activity.coinname.13004': '抽奖',
  'activity.coinname.13006': '13006',
  'activity.coinname.13007': '13007',
  'activity.coinname.13008': '13008',
  'activity.coinname.13009': '13009',
  'activity.coinname.13010': '13010',
  'activity.coinname.13011': '签到金币',
  'activity.coinname.11002': '11002',
  'activity.coinname.13012': '福卡活动金币',
  'activity.coinname.90001': '免费签到金币',
  'activity.coinname.90002': '充值赠送金币',
  'activity.coinname.90003': 'Jackpot Rewards',
  'activity.coinname.13013': '13013',
  'activity.coinname.13014': '新人特惠',
  'activity.coinname.20001': '老用户回归',
  'activity.coinname.20002': 'Thưởng phiên bản cập nhật',
  'activity.coinname.13002': '代理佣金',
  'activity.coinname.13003': 'VIP返利金币',
  'activity.coinname.chips': '余额',
  'activity.coinname.rechargeChips': '充值余额',
}