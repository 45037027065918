import header from "./CN/header";
import navbar from "./CN/navbar";
import login from "./CN/login";
import home from "./CN/home";
import mine from "./CN/mine";
import acting from "./CN/acting";
import wallet from "./CN/wallet";
import activity from "./CN/activity";
import register_gift from "./CN/register_gift";
import daily_checkin from "./CN/daily_checkin";
import newcomer_gift from "./CN/newcomer_gift";
import notice from "./CN/notice";
import public_components from "./CN/public_components";


export default {
  ...header,
  ...navbar,
  ...login,
  ...home,
  ...mine,
  ...acting,
  ...activity,
  ...register_gift,
  ...daily_checkin,
  ...newcomer_gift,
  ...public_components,

  ...wallet,
  ...notice
};
