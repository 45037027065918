export default {
  'register_gift.popup.标题': '新人特惠',
  'register_gift.popup.描述': '至高',
  'register_gift.popup.详情': '详情',
  'register_gift.popup.赠送金币解锁后无需流水即可提现': '赠送金币解锁后无需流水即可提现',
  'register_gift.popup.赠送': '赠送',
  'register_gift.popup.立即充值': '立即充值',
  'register_gift.popup.首次充值': '首次充值',
  'register_gift.popup.二次充值': '二次充值',
  'register_gift.popup.三次充值': '三次充值',
  'register_gift.popup.四次充值': '四次充值',

  'register_gift.标题': '新人特惠',
  'register_gift.首次充值': '首次充值',
  'register_gift.送全部注册金币': '送全部注册金币的{rate}%',
  'register_gift.赠送金额': '赠送金额',
  'register_gift.玩法': '玩法',
  'register_gift.玩法规则1': '1.完成任意充值即可获得全部注册送金额的{rate}%',
  'register_gift.玩法规则1.1': '1.完成任意充值可赠送充值金额的{rate}%',
  'register_gift.玩法规则2': '2.赠送金额为未解锁金币!需要充值金流水进行解锁',
  'register_gift.玩法规则3': '3.解锁规则为:充值金流水',
  'register_gift.玩法规则4': '4.解锁金币提现无流水要求',
  'register_gift.举例': '举例',
  'register_gift.赠送金币为': '赠送金币为',
  'register_gift.当前充值金流水': '当前充值金流水',
  'register_gift.领取解锁金币后将重新计算': '领取解锁金币后将重新计算',
  'register_gift.可解锁金币': '可解锁金币',
  'register_gift.领取后赠送金币余额': '领取后赠送金币余额',
  'register_gift.立即充值': '立即充值',

  'register_gift.锁定金币总余额': '锁定金币总余额',
  'register_gift.充值金流水': '充值金流水',
  'register_gift.锁定金币': '锁定金币',
  'register_gift.速率': '速率',
  'register_gift.已领取金币': '已领取金币',
  'register_gift.可领取金币': '可领取金币',
  'register_gift.领取金币': '领取金币',
  'register_gift.首充赠送金币': '首充赠送金币',
  'register_gift.二充赠送金币': '二充赠送金币',
  'register_gift.三充赠送金币': '三充赠送金币',
  'register_gift.四充赠送金币': '四充赠送金币',

  'register_gift.第x次充值': '第{num}次充值',
  'register_gift.送充值金额': '送充值金额',
  'register_gift.充值金额': '充值金额',

  'register_gift.恭喜你已获得所有新手奖励': '恭喜你已获得所有新手奖励',
  'register_gift.如何玩': 'How to play',
  'register_gift.充值金币流水可解锁赠送金币': '充值金币流水可解锁赠送金币',
  'register_gift.解锁金币计算公式': '解锁金币计算公式',
  'register_gift.充值金流水*当前解锁金币速率': '充值金流水*当前解锁金币速率',
  'register_gift.解锁速率': '解锁速率',
  'register_gift.解锁优先级': '解锁优先级',
  'register_gift.查看规则': '查看规则',
  'register_gift.充值金流水越高,解锁金币越多': '充值金流水越高,解锁金币越多',

  'register_gift.header.锁定金额': '锁定金额',
  'register_gift.header.可解锁': '可解锁',
  'register_gift.header.领取': '领取',

}